/* global Excel */

import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { getRefreshTarget, refreshData } from "../../commands/commands";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { ArrowClockwise24Filled } from "@fluentui/react-icons";
import { Button, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    padding: "0.5rem 1rem 0rem",
  },
});

export default function Refresh() {
  const styles = useStyles();

  const [lastRefreshed, setLastRefreshed] = useState<Dayjs | undefined>(undefined);

  const handleRefreshEvent = useCallback((e: CustomEvent) => {
    setLastRefreshed(e.detail);
  }, []);

  useEffect(() => {
    const refreshTarget = getRefreshTarget();
    refreshTarget.addEventListener("refresh", handleRefreshEvent);
    return () => {
      refreshTarget.removeEventListener("refresh", handleRefreshEvent);
    };
  }, [handleRefreshEvent]);

  useQuery({
    queryKey: ["lastRefreshed"],
    queryFn: async () => {
      return await Excel.run(async (context) => {
        const entry = context.workbook.settings.getItemOrNullObject("lastRefreshed");
        entry.load("value");
        await context.sync();
        if (entry.isNullObject) {
          return null;
        }
        const timeString = entry.value as string;
        const parsed = dayjs(timeString);
        setLastRefreshed(parsed);
        return parsed;
      });
    },
    refetchInterval: 5000,
  });

  return (
    <div className={styles.container}>
      <Button icon={<ArrowClockwise24Filled />} onClick={refreshData} />
      <span>{lastRefreshed ? `Last refreshed: ${lastRefreshed.fromNow()}` : "Refresh workbook"}</span>
    </div>
  );
}
