import { Configuration } from "@azure/msal-browser";
import { Resource } from "@opentelemetry/resources";
import { DiagLogLevel } from "@opentelemetry/api";
import { Environment, DdbEnvironment } from "../shared/types";
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
} from "@opentelemetry/semantic-conventions";
import PackageJSON from "../../package.json";
import { Snippet } from "@microsoft/applicationinsights-web";
import { PostHogConfig } from "posthog-js";

/* application metadata */
const { name, version } = PackageJSON;

/* environment variables */
export const ENVIRONMENT = process.env.ENVIRONMENT !== undefined ? String(process.env.ENVIRONMENT) : Environment.Local;
export const APPINSIGHTS_CONNECTION_STRING =
  process.env.APPINSIGHTS_CONNECTION_STRING !== undefined ? String(process.env.APPINSIGHTS_CONNECTION_STRING) : "";
export const POSTHOG_API_KEY = process.env.POSTHOG_API_KEY !== undefined ? String(process.env.POSTHOG_API_KEY) : "";
export const POSTHOG_HOST = process.env.POSTHOG_HOST !== undefined ? String(process.env.POSTHOG_HOST) : "";
export const MSAL_CLIENT_ID = process.env.MSAL_CLIENT_ID !== undefined ? String(process.env.MSAL_CLIENT_ID) : "";
export const MSAL_AUTHORITY = process.env.MSAL_AUTHORITY !== undefined ? String(process.env.MSAL_AUTHORITY) : "";
export const OTEL_DIAG_LOGGER_LEVEL =
  process.env.OTEL_DIAG_LOGGER_LEVEL !== undefined ? parseInt(process.env.OTEL_DIAG_LOGGER_LEVEL) : DiagLogLevel.ERROR;

/* ddb environment */
function ddbEnvironment(): DdbEnvironment {
  switch (ENVIRONMENT) {
    case Environment.Local:
      return DdbEnvironment.Develop;
    case Environment.Development:
      return DdbEnvironment.Develop;
    case Environment.UT:
      return DdbEnvironment.Sandbox;
    case Environment.Production:
      return DdbEnvironment.Production;
    default:
      return DdbEnvironment.Develop;
  }
}

export const DDB_ENVIRONMENT: DdbEnvironment = ddbEnvironment();

/* ddb-excel opentelemetry resource */
export const OTEL_RESOURCE = new Resource({
  [ATTR_SERVICE_NAME]: name,
  [ATTR_SERVICE_VERSION]: version,
  [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: ENVIRONMENT,
  "DDB.Environment": DDB_ENVIRONMENT,
});

/* application insights configuration */
export const APPLICATION_INSIGHTS_CONFIG: Partial<Snippet["config"]> = {
  connectionString: APPINSIGHTS_CONNECTION_STRING,
  enableDebug: ENVIRONMENT === Environment.Local || ENVIRONMENT === Environment.Development,
  enableAutoRouteTracking: true,
  autoTrackPageVisitTime: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
};

/* posthog configuration */
export const POSTHOG_CONFIG: Partial<PostHogConfig> = { api_host: POSTHOG_HOST };

/* msal configuration */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: MSAL_AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  telemetry: {
    application: {
      appName: name,
      appVersion: version,
    },
  },
};
