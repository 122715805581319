/* global Office, Excel */

import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { RefreshableBatcher } from "../shared/batch";
import { getGlobal } from "../shared/global";

dayjs.extend(relativeTime);

const g = getGlobal();

// The add-in command functions need to be available in global scope
g.refreshDataEvent = refreshDataEvent;

export function getBatchers(): RefreshableBatcher[] {
  if (g.batchers === undefined) {
    g.batchers = [];
  }
  return g.batchers;
}

export function getRefreshTarget(): RefreshTarget {
  if (g.refreshTarget === undefined) {
    g.refreshTarget = new RefreshTarget();
  }
  return g.refreshTarget;
}

async function refreshDataEvent(event: Office.AddinCommands.Event) {
  await refreshData();
  /* complete event */
  event.completed();
}

export async function refreshData() {
  await Promise.all(getBatchers().map((batcher) => batcher.refresh()));
  await Excel.run(async (context) => {
    const date = dayjs();
    const dateString = date.toISOString();
    const entry = context.workbook.settings.getItemOrNullObject("lastRefreshed");
    await context.sync();
    if (entry.isNullObject) {
      context.workbook.settings.add("lastRefreshed", dateString);
    } else {
      entry.set({
        value: dateString,
      });
    }
    await context.sync();
    getRefreshTarget().emitRefreshEvent(date);
  });
}

class RefreshTarget extends EventTarget {
  emitRefreshEvent(date: Dayjs) {
    this.dispatchEvent(new CustomEvent("refresh", { detail: date }));
  }
}
