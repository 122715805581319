import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import {
  Button,
  makeStyles,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
  tokens,
} from "@fluentui/react-components";
import { VirtualizerScrollView } from "@fluentui/react-components/unstable";
import { templateService } from "../../shared/ddb";
import { DdbEnvironment } from "../../shared/types";
import { ProjectSchema } from "@ddb/environment-context-service";
import { TemplateSchema } from "@ddb/template-service";
import { STORE } from "../store";

async function getTemplates(
  environment: DdbEnvironment,
  projectId: ProjectSchema["project_id"]
): Promise<TemplateSchema[]> {
  try {
    const env = templateService(environment);
    const res = await env.getTemplates({ projectId: [projectId] });
    return res.data.templates;
  } catch {
    throw new Error(`Failed to get templates for DDB (${environment}).`);
  }
}

const useStyles = makeStyles({
  template: {
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    borderBottom: "1px solid " + tokens.colorNeutralStroke2,
    height: "30px",
  },
});

const TemplateSelect: React.FC = () => {
  const styles = useStyles();
  const { dispatchToast } = useToastController("global-toaster");
  const { project_id, environment } = useStore(STORE, ({ currentQuery }) => currentQuery);

  const templateQuery = useQuery<TemplateSchema[]>({
    queryKey: ["templates", environment, project_id],
    retry: false,
    queryFn: () =>
      getTemplates(environment, project_id || "").catch((error) => {
        dispatchToast(
          <Toast>
            <ToastTitle>Error</ToastTitle>
            <ToastBody subtitle="Error">Failed to get templates for the project.</ToastBody>
          </Toast>,
          { intent: "error" }
        );
        throw error;
      }),
  });

  const templates = templateQuery.data || [];

  return (
    <div>
      <p>Select Template</p>

      <VirtualizerScrollView
        numItems={templates.length}
        itemSize={30}
        container={{ role: "list", style: { height: "500px" } }}
      >
        {(index, isScrolling) => {
          const template = templates[index];
          return (
            <div
              className={styles.template}
              role={"listitem"}
              aria-posinset={index}
              aria-setsize={templates.length}
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? tokens.colorNeutralBackground1 : tokens.colorNeutralBackground2,
              }}
            >
              <span>{template.name}</span>
              <Button
                size="small"
                disabled={isScrolling}
                onClick={() =>
                  STORE.setState((state) => ({
                    ...state,
                    step: 3,
                    currentQuery: { ...state.currentQuery, template_id: template.id },
                  }))
                }
              >
                Select
              </Button>
            </div>
          );
        }}
      </VirtualizerScrollView>
    </div>
  );
};

export default TemplateSelect;
