import { DdbEnvironment } from "../shared/types";
import { DDB_ENVIRONMENT } from "../shared/config";

export const EXCEL_FUNCTION = {
  PROJECT: {
    NAME: "PROJECT.NAME",
  },
  ASSET: {
    NAME: "ASSET.NAME",
    PARENT_ID: "ASSET.PARENT_ID",
    CHILD_IDS: "ASSET.CHILD_IDS",
    ASSET_TYPE_ID: "ASSET.ASSET_TYPE_ID",
    ASSET_TYPE_NAME: "ASSET.ASSET_TYPE_NAME",
    PATH: "ASSET.PATH",
  },
  PARAMETER: {
    PARAMETER_TYPE_NAME: "PARAMETER.PARAMETER_TYPE_NAME",
    VALUE: "PARAMETER.VALUE",
    SOURCE_ID: "PARAMETER.SOURCE_ID",
    STATUS: "PARAMETER.STATUS",
    UNIT_SYMBOL: "PARAMETER.UNIT_SYMBOL",
    ASSET_PARAMETER_ID: "PARAMETER.ASSET_PARAMETER_ID",
    ASSET_PARAMETER_VALUE: "PARAMETER.ASSET_PARAMETER_VALUE",
    ASSET_PARAMETER_UNIT_SYMBOL: "PARAMETER.ASSET_PARAMETER_UNIT_SYMBOL",
  },
  PARAMETER_TYPE: {
    NAME: "PARAMETER_TYPE.NAME",
  },
} as const;

export type ExcelFunction =
  | (typeof EXCEL_FUNCTION.PROJECT)[keyof typeof EXCEL_FUNCTION.PROJECT]
  | (typeof EXCEL_FUNCTION.PARAMETER)[keyof typeof EXCEL_FUNCTION.PARAMETER]
  | (typeof EXCEL_FUNCTION.ASSET)[keyof typeof EXCEL_FUNCTION.ASSET]
  | (typeof EXCEL_FUNCTION.PARAMETER_TYPE)[keyof typeof EXCEL_FUNCTION.PARAMETER_TYPE];

export function createFunction(excelFunction: ExcelFunction, ...args: Array<string | number>): string {
  const functionArgs = args.map((arg) => (typeof arg === "string" ? `"${arg}"` : arg)).join(", ");
  const scope = DDB_ENVIRONMENT === DdbEnvironment.Production ? "DDB" : `DDB.${DDB_ENVIRONMENT.toUpperCase()}`;
  return `=LET(result, ${scope}.${excelFunction}(${functionArgs}), IF(ISNA(result), "", result))`;
}
