/* global Excel */

import React, { FC, useState, useCallback, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { BrowserRouter, Routes, Route, NavLink } from "react-router";
import { QueryClient, QueryClientProvider, useQuery, useIsFetching } from "@tanstack/react-query";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import {
  Typography,
  CssBaseline,
  Stack,
  Toolbar,
  AppBar,
  Box,
  IconButton,
  ThemeProvider as MUIThemeProvider,
  useTheme,
} from "@mui/material";
import { ArrowSyncCircleRegular } from "@fluentui/react-icons";
import { getRefreshTarget, refreshData } from "../../commands/commands";
import { MUI_THEME, FLUENT_THEME } from "../styles/theme";
import { FluentProvider as FluentThemeProvider, ProgressBar, Toaster, makeStyles } from "@fluentui/react-components";
import SavedQueries from "./SavedQueries";
import CreateQuery from "./ProjectSelect";
import TemplateSelect from "./TemplateSelect";
import DatasetSelect from "./DatasetSelect";
import EditQuery from "./SelectParameters";
import { BottomBar } from "./BottomBar";

const QUERY_CLIENT = new QueryClient();

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  progressBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  content: {
    padding: "1rem",
    overflowY: "scroll",
    flexGrow: 100,
  },
});

const Refresh: FC = () => {
  const [lastRefreshed, setLastRefreshed] = useState<Dayjs | undefined>(undefined);
  const handleRefreshEvent = useCallback((e: CustomEvent) => {
    setLastRefreshed(e.detail);
  }, []);
  useQuery({
    queryKey: ["lastRefreshed"],
    queryFn: async () => {
      return await Excel.run(async (context) => {
        const entry = context.workbook.settings.getItemOrNullObject("lastRefreshed");
        entry.load("value");
        await context.sync();
        if (entry.isNullObject) {
          return null;
        }
        const timeString = entry.value as string;
        const parsed = dayjs(timeString);
        setLastRefreshed(parsed);
        return parsed;
      });
    },
    refetchInterval: 5000,
  });

  useEffect(() => {
    const refreshTarget = getRefreshTarget();
    refreshTarget.addEventListener("refresh", handleRefreshEvent);
    return () => {
      refreshTarget.removeEventListener("refresh", handleRefreshEvent);
    };
  }, [handleRefreshEvent]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton onClick={refreshData}>
        <ArrowSyncCircleRegular />
      </IconButton>
      <Typography>Last Refreshed: {lastRefreshed?.fromNow()}</Typography>
    </Stack>
  );
};

const Loading: FC = () => {
  const styles = useStyles();
  const loading = useIsFetching({ predicate: (query) => query.queryKey[0] !== "lastRefreshed" });

  return loading === 0 ? null : <ProgressBar color="brand" className={styles.progressBar} />;
};

const DDBIcon: FC = () => {
  const theme = useTheme();
  return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.7083 12.1667V7.79167H7.29167V12.1667H13.125C13.125 13.327 13.5859 14.4398 14.4064 15.2603C15.2269 16.0807 16.3397 16.5417 17.5 16.5417C18.6603 16.5417 19.7731 16.0807 20.5936 15.2603C21.4141 14.4398 21.875 13.327 21.875 12.1667H27.7083ZM27.7083 4.875C28.4819 4.875 29.2237 5.18229 29.7707 5.72927C30.3177 6.27625 30.625 7.01812 30.625 7.79167V18C30.625 18.7735 30.3177 19.5154 29.7707 20.0624C29.2237 20.6094 28.4819 20.9167 27.7083 20.9167H7.29167C6.51812 20.9167 5.77625 20.6094 5.22927 20.0624C4.68229 19.5154 4.375 18.7735 4.375 18V7.79167C4.375 7.01812 4.68229 6.27625 5.22927 5.72927C5.77625 5.18229 6.51812 4.875 7.29167 4.875H27.7083ZM4.375 22.375H13.125C13.125 23.5353 13.5859 24.6481 14.4064 25.4686C15.2269 26.2891 16.3397 26.75 17.5 26.75C18.6603 26.75 19.7731 26.2891 20.5936 25.4686C21.4141 24.6481 21.875 23.5353 21.875 22.375H30.625V28.2083C30.625 28.9819 30.3177 29.7237 29.7707 30.2707C29.2237 30.8177 28.4819 31.125 27.7083 31.125H7.29167C6.51812 31.125 5.77625 30.8177 5.22927 30.2707C4.68229 29.7237 4.375 28.9819 4.375 28.2083V22.375Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

const NavigationBar: FC = () => {
  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: 4 }}>
          <Stack
            component={NavLink}
            to="/"
            sx={{ textDecoration: "none", color: "inherit", flexGrow: 1 }}
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="start"
          >
            <DDBIcon />
            <Stack direction="column" spacing={0.2} justifyContent="start" alignItems="start">
              <Typography variant="h6">Digital Design Brief</Typography>
              <Typography variant="body2">EXCEL CONNECTOR Beta</Typography>
            </Stack>
          </Stack>
          <Refresh />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const App: FC = () => {
  const styles = useStyles();
  const posthog = usePostHog();

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={QUERY_CLIENT}>
        <FluentThemeProvider theme={FLUENT_THEME}>
          <MUIThemeProvider theme={MUI_THEME}>
            <CssBaseline />
            <BrowserRouter basename="taskpane.html">
              <div className={styles.root}>
                <Toaster toasterId="global-toaster" />
                <Loading />
                <NavigationBar />
                <div className={styles.content}>
                  <Routes>
                    <Route path="/" element={<SavedQueries />} />
                    <Route path="/create" element={<CreateQuery />} />
                    <Route path="/create/template" element={<TemplateSelect />} />
                    <Route path="/create/dataset" element={<DatasetSelect />} />
                    <Route path="/edit" element={<EditQuery />} />
                  </Routes>
                </div>
                <BottomBar />
              </div>
            </BrowserRouter>
          </MUIThemeProvider>
        </FluentThemeProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};
