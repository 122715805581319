import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrandVariants, FluentProvider, Theme, createLightTheme, makeStyles } from "@fluentui/react-components";
import Layout from "./Layout";

const queryClient = new QueryClient();

const useStyles = makeStyles({
  root: { height: "100vh" },
  provider: { height: "100%" },
});
const ddbExcel: BrandVariants = {
  10: "#060200",
  20: "#251207",
  30: "#3E1B0D",
  40: "#542110",
  50: "#6A2711",
  60: "#802D12",
  70: "#983313",
  80: "#B03913",
  90: "#C93F14",
  100: "#D94F23",
  110: "#E2663C",
  120: "#E97B54",
  130: "#F0906D",
  140: "#F5A486",
  150: "#FAB8A0",
  160: "#FDCCBA",
};

const lightTheme: Theme = { ...createLightTheme(ddbExcel) };

const App: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <QueryClientProvider client={queryClient}>
        <FluentProvider theme={lightTheme} className={styles.provider}>
          <Layout />
        </FluentProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
