/* global Excel */

export enum fillColor {
  darkBlue = "#31869B",
  mediumBlue = "#92cddc",
  lightBlue = "#daeef3",
  darkGreen = "#76933c",
  mediumGreen = "#c4d79b",
  lightGreen = "#d8e4bc",
  highlightGreen = "#b3ddb0",
}

const continuousMediumBorder = {
  style: Excel.BorderLineStyle.continuous,
  weight: Excel.BorderWeight.medium,
};
export const continuousThinBorder = {
  style: Excel.BorderLineStyle.continuous,
  weight: Excel.BorderWeight.thin,
};

export function setCellStyle(
  fill: fillColor,
  bold: boolean,
  italic: boolean = false,
  fontColor: string = "Black",
  hAlignment: Excel.HorizontalAlignment = Excel.HorizontalAlignment.center,
  vAlignment: Excel.VerticalAlignment = Excel.VerticalAlignment.center,
  border: { style: Excel.BorderLineStyle; weight: Excel.BorderWeight } = continuousMediumBorder
): Excel.SettableCellProperties {
  return {
    format: {
      fill: {
        color: fill,
      },
      font: {
        bold,
        italic,
        color: fontColor,
      },
      horizontalAlignment: hAlignment,
      verticalAlignment: vAlignment,
      borders: {
        bottom: border,
        left: border,
        right: border,
        top: border,
      },
    },
  };
}
