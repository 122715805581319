import { ResourceAttributes } from "@opentelemetry/resources";

type AttributeValue = string | number | boolean | null | undefined;

export function humanizeString(str: AttributeValue | AttributeValue[] | undefined): string {
  if (str === undefined) {
    return "N/A";
  }

  return Array.isArray(str)
    ? str.map(humanizeString).join(", ")
    : String(str)
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (s) => s.toUpperCase())
        .trim();
}

export function attrsToHumanString(attrs: ResourceAttributes, separator = ", "): string {
  return Object.entries(attrs)
    .map(([key, value]) => `${humanizeString(key)}: ${humanizeString(value)}`)
    .join(separator);
}
