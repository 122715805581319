import {
  AuthenticationResult,
  IPublicClientApplication,
  SilentRequest,
  createNestablePublicClientApplication,
} from "@azure/msal-browser";

import { MSAL_CONFIG } from "./config";

class Msal {
  private pca: IPublicClientApplication | undefined = undefined;

  public async initialize(): Promise<void> {
    this.pca = await createNestablePublicClientApplication(MSAL_CONFIG);
    return void 0;
  }

  /* this function may throw and should be called from a try/catch block */
  public async ssoGetToken(scopes: string[]): Promise<string> {
    const userAccount = await this.ssoGetUserIdentity(scopes);
    return userAccount.accessToken;
  }

  /* this function may throw and should be called from a try/catch block */
  public async ssoGetUserIdentity(scopes: string[]): Promise<AuthenticationResult> {
    if (this.pca === undefined) {
      this.pca = await createNestablePublicClientApplication(MSAL_CONFIG);
    }
    const request: SilentRequest = { scopes: scopes, account: this.pca.getAllAccounts()[0] };
    try {
      try {
        return await this.pca.acquireTokenSilent(request);
      } catch (error) {
        /* fallback to interactive token acquisition */
        return await this.pca.acquireTokenPopup(request);
      }
    } catch (error) {
      throw new Error(`Unable to acquire access token: ${error}`);
    }
  }
}

export const msal = new Msal();
