/*global CustomFunctions */

import { ProjectSchema } from "@ddb/environment-context-service";
import { AssetHierarchyRef } from "@ddb/parameter-service";
import { FormulaErrorFormat } from "./types";

export function chunk<T>(array: T[], count: number): T[][] {
  if (count <= 0) {
    throw new Error("count must be greater than 0");
  }
  const result: T[][] = new Array(Math.ceil(array.length / count));
  let index = 0;
  for (let i = 0; i < array.length; i += count) {
    result[index++] = array.slice(i, i + count);
  }
  return result;
}

export function union<K, V>(maps: Map<K, V>[]): Map<K, V> {
  const result = new Map<K, V>();
  for (const map of maps) {
    for (const [key, value] of map) {
      result.set(key, value);
    }
  }
  return result;
}

export function isGuid(value: string): boolean {
  return /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/g.test(value);
}

export function formulaErrorHandling(errorType: FormulaErrorFormat): CustomFunctions.Error {
  const validErrorTypes = Object.values(FormulaErrorFormat);
  if (!validErrorTypes.includes(errorType)) {
    return new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Unknown error type");
  }
  return new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, errorType);
}

export function assetPathString(
  asssetHierarchy: AssetHierarchyRef[],
  separator: string = " > ",
  project?: ProjectSchema
): string {
  const assetHierarchy = asssetHierarchy.map((asset) => asset.name);
  return [project?.short_title, ...assetHierarchy].join(separator);
}
