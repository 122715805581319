import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from "@opentelemetry/core";
import { BatchSpanProcessor, BufferConfig, ConsoleSpanExporter } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { MeterProvider, PeriodicExportingMetricReader, ConsoleMetricExporter } from "@opentelemetry/sdk-metrics";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";
import { DiagConsoleLogger, diag, metrics, trace } from "@opentelemetry/api";
import { logs } from "@opentelemetry/api-logs";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { ENVIRONMENT, OTEL_RESOURCE, OTEL_DIAG_LOGGER_LEVEL } from "./config";
import { Environment } from "./types";
import { LoggerProvider, BatchLogRecordProcessor, ConsoleLogRecordExporter } from "@opentelemetry/sdk-logs";
import {
  ApplicationInsightsTraceExporter,
  ApplicationInsightsMetricsExporter,
  ApplicationInsightsLogExporter,
} from "./appinsights";

/* opentelemetry diagnostics */
export const DIAG_PROVIDER = new DiagConsoleLogger();

/* opentelemetry resource */
const RESOURCE = Resource.default().merge(OTEL_RESOURCE);

/* opentelemetry providers */
export const TRACE_PROVIDER = new WebTracerProvider({ resource: RESOURCE });
export const METER_PROVIDER = new MeterProvider({ resource: RESOURCE });
export const LOG_PROVIDER = new LoggerProvider({ resource: RESOURCE });

/* opentelemetry processors & readers */
function bufferConfigFactory(): BufferConfig {
  const bufferConfig: BufferConfig = {
    maxExportBatchSize: 100,
    maxQueueSize: 1000,
    scheduledDelayMillis: 5000,
    exportTimeoutMillis: 10000,
  };

  switch (ENVIRONMENT) {
    case Environment.Local:
      return {
        ...bufferConfig,
        maxExportBatchSize: 1,
        maxQueueSize: 1,
      };
    case Environment.Development:
      return {
        ...bufferConfig,
        maxExportBatchSize: 1,
        maxQueueSize: 1,
      };
    default:
      return bufferConfig;
  }
}
function metricReaderFactory(): PeriodicExportingMetricReader {
  const bufferConfig = bufferConfigFactory();
  switch (ENVIRONMENT) {
    case Environment.Local:
      return new PeriodicExportingMetricReader({
        exporter: new ConsoleMetricExporter(),
        exportIntervalMillis: bufferConfig.scheduledDelayMillis,
        exportTimeoutMillis: bufferConfig.exportTimeoutMillis,
      });
    default:
      return new PeriodicExportingMetricReader({
        exporter: new ApplicationInsightsMetricsExporter(),
        exportIntervalMillis: bufferConfig.scheduledDelayMillis,
        exportTimeoutMillis: bufferConfig.exportTimeoutMillis,
      });
  }
}
function spanProcessorFactory(): BatchSpanProcessor {
  const bufferConfig = bufferConfigFactory();
  switch (ENVIRONMENT) {
    case Environment.Local:
      return new BatchSpanProcessor(new ConsoleSpanExporter(), bufferConfig);
    default:
      return new BatchSpanProcessor(new ApplicationInsightsTraceExporter(), bufferConfig);
  }
}
function logRecordProcessorFactory(): BatchLogRecordProcessor {
  const bufferConfig = bufferConfigFactory();
  switch (ENVIRONMENT) {
    case Environment.Local:
      return new BatchLogRecordProcessor(new ConsoleLogRecordExporter(), bufferConfig);
    default:
      return new BatchLogRecordProcessor(new ApplicationInsightsLogExporter(), bufferConfig);
  }
}

/* opentelemetry initializer */
export function initialize() {
  diag.setLogger(DIAG_PROVIDER, OTEL_DIAG_LOGGER_LEVEL);
  logs.setGlobalLoggerProvider(LOG_PROVIDER);
  metrics.setGlobalMeterProvider(METER_PROVIDER);
  trace.setGlobalTracerProvider(TRACE_PROVIDER);
  TRACE_PROVIDER.register({
    contextManager: new ZoneContextManager(),
    propagator: new CompositePropagator({ propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator()] }),
  });
  TRACE_PROVIDER.addSpanProcessor(spanProcessorFactory());
  METER_PROVIDER.addMetricReader(metricReaderFactory());
  LOG_PROVIDER.addLogRecordProcessor(logRecordProcessorFactory());
  registerInstrumentations({
    tracerProvider: TRACE_PROVIDER,
    meterProvider: METER_PROVIDER,
    loggerProvider: LOG_PROVIDER,
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new XMLHttpRequestInstrumentation(),
      new FetchInstrumentation(),
    ],
  });
}
