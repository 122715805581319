import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import {
  Button,
  Checkbox,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  useToastController,
  tokens,
} from "@fluentui/react-components";
import { VirtualizerScrollView } from "@fluentui/react-components/unstable";
import { DataSetSchema } from "@ddb/parameter-service";
import { TemplateSchema } from "@ddb/template-service";
import { parameterService } from "../../shared/ddb";
import { isTemplateQuery, DdbEnvironment } from "../../shared/types";
import { STORE } from "../store";

async function getDatasets(environment: DdbEnvironment, templateId: TemplateSchema["id"]): Promise<DataSetSchema[]> {
  try {
    const env = parameterService(environment);
    const res = await env.getDataSets({ templateId: [templateId] });
    return res.data.data_sets;
  } catch {
    throw new Error(`Failed to get datasets for DDB (${environment}).`);
  }
}

const useStyles = makeStyles({
  dataSet: {
    display: "grid",
    gap: "1rem",
    padding: "1rem",
    gridTemplateColumns: "1fr auto",
    borderBottom: "1px solid " + tokens.colorNeutralStroke2,
    height: "30px",
  },
});

const DatasetSelect: React.FC = () => {
  const styles = useStyles();
  const { dispatchToast } = useToastController("global-toaster");
  const { environment } = useStore(STORE, ({ currentQuery }) => currentQuery);
  const template_id = useStore(STORE, ({ currentQuery }) =>
    isTemplateQuery(currentQuery) ? currentQuery.template_id : undefined
  );
  if (!template_id) throw new Error("Template is required for DatasetSelect.");
  const datasetQuery = useQuery<DataSetSchema[]>({
    queryKey: ["datasets", environment, template_id],
    retry: false,
    queryFn: () =>
      getDatasets(environment, template_id).catch((error) => {
        dispatchToast(
          <Toast>
            <ToastTitle>Error</ToastTitle>
            <ToastBody subtitle="Error">Failed to get datasets for template.</ToastBody>
          </Toast>,
          { intent: "error" }
        );
        throw error;
      }),
  });

  const datasets = datasetQuery.data || [];

  function onChange(e: React.ChangeEvent<HTMLInputElement>, dataSet: DataSetSchema) {
    const checked = e.target.checked;

    STORE.setState((state) => {
      if (!isTemplateQuery(state.currentQuery)) return state;
      const data_sets_ids = state.currentQuery.data_sets_ids || [];
      const index = data_sets_ids.findIndex((id) => id === dataSet.id);
      if (checked && index === -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: [...data_sets_ids, dataSet.id],
          },
        };
      } else if (!checked && index !== -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: data_sets_ids.filter((id) => id !== dataSet.id),
          },
        };
      }
      return state;
    });
  }

  return (
    <div>
      <p>Datasets</p>

      <VirtualizerScrollView
        numItems={datasets.length}
        itemSize={30}
        container={{ role: "list", style: { height: "500px" } }}
      >
        {(index, isScrolling) => {
          const dataset = datasets[index];
          return (
            <div
              className={styles.dataSet}
              role={"listitem"}
              aria-posinset={index}
              aria-setsize={datasets.length}
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? tokens.colorNeutralBackground1 : tokens.colorNeutralBackground2,
              }}
            >
              <Checkbox label={dataset.name} disabled={isScrolling} onChange={(e) => onChange(e, dataset)} />
            </div>
          );
        }}
      </VirtualizerScrollView>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => STORE.setState((state) => ({ ...state, step: 4 }))}>Next</Button>
      </div>
    </div>
  );
};

export default DatasetSelect;
