import React, { FC } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { CircularProgress, Stack, Typography, Card, CardActions, CardContent, Button } from "@mui/material";
import { templatesRequest } from "../../shared/requests";
import { TemplateSchema } from "@ddb/template-service";
import { STORE } from "../store";
import { CurrentQuery } from "./CurrentQuery";
import { setActionButton } from "./BottomBar";
import EmptyState from "./EmptyState";

interface TemplateProps {
  template: TemplateSchema;
}

const Template: FC<TemplateProps> = ({ template }) => {
  const navigate = useNavigate();

  function handleSelectClick() {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, template_id: template.id },
    }));
    navigate("/create/dataset");
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          {template.name}
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>{template.description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSelectClick}>
          Select
        </Button>
      </CardActions>
    </Card>
  );
};

const TemplateSelect: FC = () => {
  const { project_id } = useStore(STORE, ({ currentQuery }) => currentQuery);
  if (!project_id) return <></>;
  const templates = useQuery({
    queryKey: ["templates", project_id],
    queryFn: templatesRequest(project_id),
  });

  setActionButton(<></>);

  return (
    <Stack direction="column" spacing={8}>
      <CurrentQuery />
      <Typography variant="h4">Select Template</Typography>
      {templates.isPending && <CircularProgress sx={{ alignSelf: "center" }} />}
      {templates.isSuccess && templates.data?.size === 0 && <EmptyState text="No templates exist for project" />}
      {templates.isSuccess &&
        Array.from(templates.data?.values() || []).map((template) => (
          <Template key={template.id} template={template} />
        ))}
    </Stack>
  );
};

export default TemplateSelect;
