import { AssetTypeSchema, TemplateSchema } from "@ddb/template-service";
import { Tag } from "@ddb/parameter-metadata-service";
import { AssetSchema, DataSetSchema, ParameterSchema, ParameterTypeSchema } from "@ddb/parameter-service";
import { ProjectSchema } from "@ddb/environment-context-service";

export enum Environment {
  Local = "local",
  Development = "develop",
  UT = "ut",
  Production = "production",
}

export enum DdbEnvironment {
  Develop = "develop",
  Sandbox = "sandbox",
  Production = "production",
}

export enum QueryFormat {
  Template = "template",
  Manual = "manual",
}

export enum FormulaErrorFormat {
  ASSET_ID = "Asset ID must be a GUID.",
  PARAMETER_TYPE_ID = "Parameter type ID must be a GUID.",
  PARAMETER_ID = "Parameter ID must be a GUID.",
  INVALID_REVISION = "Invalid revision.",
  PROJECT_NUMBER = "Invalid project number. Project number must be 8 digits.",
  PROJECT_ID = "Project ID must be a GUID.",
}

export const QUERY_FORMAT_DESCRIPTIONS = {
  [QueryFormat.Template]: "Select parameters from an existing template.",
  [QueryFormat.Manual]: "Manually filter all parameters.",
};

export type Request<T> = () => Promise<T>;
export type IdMap<T, K> =
  T extends Array<infer U> ? (K extends keyof U ? Map<U[K], T> : never) : K extends keyof T ? Map<T[K], T> : never;

interface Filters {
  asset_type_ids?: Array<AssetTypeSchema["id"]>;
  asset_ids?: Array<AssetSchema["id"]>;
  tag_ids?: Array<Tag["id"]>;
  parameter_type_ids?: Array<ParameterTypeSchema["id"]>;
}

interface BaseQuery {
  format: QueryFormat;
  environment: DdbEnvironment;
  project_id?: ProjectSchema["project_id"];
  name?: string;
  filters?: Filters;
}

export interface SavedQuery extends BaseQuery {
  id: string;
  project_id: ProjectSchema["project_id"];
  name: string;
}

interface ManualQuery extends BaseQuery {
  format: QueryFormat.Manual;
}

interface TemplateQuery extends BaseQuery {
  format: QueryFormat.Template;
  template_id?: TemplateSchema["id"];
  data_sets_ids?: Array<DataSetSchema["id"]>;
}

export function isTemplateQuery(query: Query): query is TemplateQuery {
  return query.format === QueryFormat.Template;
}

export function isManualQuery(query: Query): query is ManualQuery {
  return query.format === QueryFormat.Manual;
}

export function isSavedQuery(query: SavedQuery | Query): query is SavedQuery {
  return query["id"] !== undefined;
}

export type Query = ManualQuery | TemplateQuery;

export type SavedQueries = readonly SavedQuery[];

export type CurrentQuery = Pick<Query, "format" | "environment"> & Partial<Query> & Partial<SavedQuery>;

export interface QueryResponseScheme {
  project: ProjectSchema;
  assets: Map<AssetSchema["id"], AssetSchema>;
  params: Map<ParameterSchema["id"], ParameterSchema>;
  /* TODO: convert this to a map */
  param_types: Array<ParameterSchema["parameter_type"]>;
}

export function isAsset<T extends AssetSchema | ProjectSchema>(value: T): value is T & AssetSchema {
  return (value as AssetSchema).id !== undefined;
}

export function isProject<T extends AssetSchema | ProjectSchema>(value: T): value is T & ProjectSchema {
  return (value as ProjectSchema).project_id !== undefined;
}

export function isAssetType<T extends AssetTypeSchema | object | string>(value: T): value is T & AssetTypeSchema {
  return (value as AssetTypeSchema).id !== undefined;
}
