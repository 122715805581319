import React from "react";
import {
  Button,
  Field,
  makeStyles,
  SearchBox,
  Select,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { ProjectSchema } from "@ddb/environment-context-service";
import { first } from "../../shared/asyncIterator";
import { environmentContextService, unpaginate } from "../../shared/ddb";
import { DdbEnvironment, QueryFormat } from "../../shared/types";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { STORE } from "../store";
import { humanizeString } from "../utils";

async function getProject(environment: DdbEnvironment, projectNumber: ProjectSchema["number"]): Promise<ProjectSchema> {
  try {
    const env = environmentContextService(environment);
    const project = await first(
      unpaginate(
        (after) => env.getProjects({ number: [projectNumber], after }),
        (res) => res.data.projects,
        (res) => res.data.paging?.cursors?.after
      ),
      (project) => project.number === projectNumber
    );
    if (!project) {
      throw new Error(`Project number ${projectNumber} not found in DDB (${environment}).`);
    }
    return project;
  } catch {
    throw new Error(`Failed to get project data for project number ${projectNumber} in DDB (${environment}).`);
  }
}

const useStyles = makeStyles({
  form: { display: "grid", gap: "0.5rem" },
  fwField: { width: "100%" },
  searchBox: { width: "100%" },
  "fui-SearchBox": {
    width: "100%",
    maxWidth: "100%",
  },
});

const ProjectSelect: React.FC = () => {
  const { dispatchToast } = useToastController("global-toaster");
  const styles = useStyles();
  const { environment, format } = useStore(STORE, ({ currentQuery }) => currentQuery);
  const [projectNumber, setProjectNumber] = React.useState<string>("");
  const projectQuery = useQuery<ProjectSchema>({
    queryKey: ["project", environment, projectNumber],
    enabled: false,
    retry: false,
    queryFn: () =>
      getProject(environment, projectNumber)
        .then((project) => {
          STORE.setState((state) => ({
            ...state,
            step: format === QueryFormat.Manual ? 4 : 2,
            currentQuery: { ...state.currentQuery, project_id: project.project_id },
          }));
          return project;
        })
        .catch((error) => {
          dispatchToast(
            <Toast>
              <ToastTitle>Error</ToastTitle>
              <ToastBody subtitle="Error">
                Failed to get project data for project number {projectNumber} in DDB ({environment}).
              </ToastBody>
            </Toast>,
            { intent: "error" }
          );
          throw error;
        }),
  });

  return (
    <div className={styles.form}>
      <Field className={styles.fwField}>
        <Select
          className={styles.fwField}
          value={environment}
          onChange={(e) => {
            const value = e.target.value as DdbEnvironment;
            STORE.setState((state) => ({ ...state, currentQuery: { ...state.currentQuery, environment: value } }));
          }}
        >
          {Object.values(DdbEnvironment).map((env) => (
            <option key={env} value={env}>
              {env}
            </option>
          ))}
        </Select>
      </Field>

      <Field className={styles.fwField}>
        <Select
          className={styles.fwField}
          value={format}
          onChange={(e) => {
            const value = e.target.value as QueryFormat;
            STORE.setState((state) => ({ ...state, currentQuery: { ...state.currentQuery, format: value } }));
          }}
        >
          {Object.values(QueryFormat).map((format) => (
            <option key={format} value={format}>
              {humanizeString(format)}
            </option>
          ))}
        </Select>
      </Field>

      <Field className={styles.fwField}>
        <SearchBox
          className={styles.searchBox}
          placeholder="Project Number"
          disabled={projectQuery.isLoading}
          onChange={(_, data) => setProjectNumber(data.value)}
        />
      </Field>

      <Button className={styles.fwField} onClick={() => projectQuery.refetch()} disabled={projectQuery.isLoading}>
        GO TO PROJECT
      </Button>
    </div>
  );
};

export default ProjectSelect;
