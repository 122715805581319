/* global Excel, Office */

import { StoreSchema } from "../store";
import { logs, SeverityNumber } from "@opentelemetry/api-logs";

const XML_NS = "https://ddb.arup.com";
const logger = logs.getLogger("custom-xml");

export async function save(state: Partial<StoreSchema>) {
  Excel.run(async (context) => {
    let xmlParts = context.workbook.customXmlParts;
    let parts = context.workbook.customXmlParts.getByNamespace(XML_NS);
    // eslint-disable-next-line office-addins/no-navigational-load
    parts.load("items");
    await context.sync();
    for (let part of parts.items) {
      try {
        part.delete();
        // eslint-disable-next-line office-addins/no-context-sync-in-loop
        await context.sync();
      } catch (error) {
        logger.emit({
          severityNumber: SeverityNumber.ERROR,
          body: "Failed to delete custom xml part",
          attributes: { error },
        });
      }
    }

    let xml = `<data xmlns="${XML_NS}"><state>${JSON.stringify(state)}</state></data>`;

    xmlParts.add(xml);
    await context.sync();
  });
}

export async function load(): Promise<Partial<StoreSchema> | null> {
  let state = null;
  await Office.onReady();
  try {
    await Excel.run(async (context) => {
      let parts = context.workbook.customXmlParts.getByNamespace(XML_NS);
      let partsCount = parts.getCount();
      await context.sync();
      // eslint-disable-next-line office-addins/load-object-before-read
      if (partsCount.value !== 1) return null;
      let xml = parts.getOnlyItem();
      let xmlBlob = xml.getXml();
      await context.sync();
      const jsonString = xmlBlob.value.match(/<state>(.*)<\/state>/)?.[1];
      if (jsonString) {
        state = JSON.parse(jsonString);
      }
      return null;
    });
  } catch (error) {
    logger.emit({
      severityNumber: SeverityNumber.ERROR,
      body: "Failed to load custom xml part",
      attributes: { error },
    });
  }
  if (state) return state;
  return null;
}
