import { Configuration } from "@azure/msal-browser";

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: "88ded6e6-c7e9-4ffc-9a78-b7afc0072a30",
    authority: "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  telemetry: {
    application: {
      appName: "DDB - Excel",
      appVersion: "1.0.0",
    },
  },
};
