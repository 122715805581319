import { trace, SpanStatusCode } from "@opentelemetry/api";
import { ProjectSchema } from "@ddb/environment-context-service";
import { TemplateSchema } from "@ddb/template-service";
import {
  AssetSchema,
  AssetHierarchyRef,
  AssetTypeSchema,
  DataSetSchema,
  ParameterSchema,
  ParameterTypeSchema,
  ParameterApiGetAssetsRequest,
  ParameterApiGetAllAssetTypesRequest,
} from "@ddb/parameter-service";
import { ParameterMetadataApiGetTagsRequest, Tag } from "@ddb/parameter-metadata-service";
import { Request, IdMap, CurrentQuery, QueryResponseScheme, isTemplateQuery } from "./types";
import {
  parameterService,
  parameterMetadataService,
  environmentContextService,
  templateService,
  unpaginate,
} from "./ddb";
import { toMap } from "./asyncIterator";
import { isGuid } from "./helpers";

function traceRequest<T>(operation: string, fn: () => Promise<T>): () => Promise<T> {
  const tracer = trace.getTracer("ddb");
  return async () => {
    const span = tracer.startSpan(operation);
    try {
      return await fn();
    } catch (error) {
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      throw error;
    } finally {
      span.end();
    }
  };
}

export function tagsRequest(requestParamters: ParameterMetadataApiGetTagsRequest): Request<IdMap<Tag, "id">> {
  return traceRequest("tagsRequest", async () => {
    const parameterMetadataServiceClient = parameterMetadataService();
    return await toMap(
      unpaginate(
        (after) => parameterMetadataServiceClient.getTags({ ...requestParamters, after }),
        (data) => data.data.tags,
        (data) => data.data.paging?.cursors?.after
      ),
      (tag) => tag.id,
      (tag) => tag
    );
  });
}

export function assetsRequest(requestParamters: ParameterApiGetAssetsRequest): Request<IdMap<AssetSchema, "id">> {
  return traceRequest("assetsRequest", async () => {
    const parameterClient = parameterService();
    return await toMap(
      unpaginate(
        (after) => parameterClient.getAssets({ ...requestParamters, after }),
        (data) => data.data.assets,
        (data) => data.data.paging?.cursors?.after
      ),
      (asset) => asset.id,
      (asset) => asset
    );
  });
}

export function assetTypesRequest(
  requestParamters: ParameterApiGetAllAssetTypesRequest
): Request<IdMap<AssetTypeSchema, "id">> {
  return traceRequest("assetTypesRequest", async () => {
    const parameterClient = parameterService();
    return await toMap(
      unpaginate(
        (after) => parameterClient.getAllAssetTypes({ ...requestParamters, after }),
        (data) => data.data.asset_types,
        (data) => data.data.paging?.cursors?.after
      ),
      (asset) => asset.id,
      (asset) => asset
    );
  });
}

export function parametersTypesRequest(
  requestParmeters: ParameterApiGetAllAssetTypesRequest
): Request<IdMap<ParameterTypeSchema, "id">> {
  return traceRequest("parametersTypesRequest", async () => {
    const parameterClient = parameterService();
    return await toMap(
      unpaginate(
        (after) => parameterClient.getAllParameterTypes({ ...requestParmeters, after }),
        (data) => data.data.parameter_types,
        (data) => data.data.paging?.cursors?.after
      ),
      (asset) => asset.id,
      (asset) => asset
    );
  });
}
export function queryRequest(query: CurrentQuery): Request<QueryResponseScheme> {
  return traceRequest("queryRequest", async () => {
    const environmentContextClient = environmentContextService();
    const parameterClient = parameterService();
    const [project, assets, parameters, projectParameters] = await Promise.all([
      environmentContextClient
        .getProjectById({ projectId: query.project_id as unknown as object })
        .then((data) => data.data.project),
      toMap(
        unpaginate(
          (after) =>
            parameterClient.getAssets({
              after,
              projectId: [query.project_id || ""],
              assetId: query.filters?.asset_ids,
              assetTypeId: query.filters?.asset_type_ids,
            }),
          (data) => data.data.assets,
          (data) => data.data.paging?.cursors?.after
        ),
        (asset) => asset.id,
        (asset) => asset
      ),
      toMap(
        unpaginate(
          (after) =>
            parameterClient.getAllParameters({
              after,
              projectId: [query.project_id || ""],
              assetId: query.filters?.asset_ids,
              assetType: query.filters?.asset_type_ids,
              parameterTypeId: query.filters?.parameter_type_ids,
              dataSetId: isTemplateQuery(query) ? query.data_sets_ids : undefined,
            }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
      toMap(
        unpaginate(
          (after) =>
            parameterClient.getAllParameters({
              after,
              projectId: [query.project_id || ""],
              assetId: query.filters?.asset_ids,
              assetType: query.filters?.asset_type_ids,
              parameterTypeId: query.filters?.parameter_type_ids,
              dataSetId: isTemplateQuery(query) ? query.data_sets_ids : undefined,
              projectParameter: true,
            }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
    ]);
    const allParameters = new Map([...parameters, ...projectParameters]);
    const allParameterTypes = new Array<ParameterSchema["parameter_type"]>();
    for (const param of allParameters.values()) {
      if (!allParameterTypes.some((type) => type.id === param.parameter_type.id)) {
        allParameterTypes.push(param.parameter_type);
      }
    }
    return { project: project, assets, params: allParameters, param_types: allParameterTypes };
  });
}

export function projectsRequest(projectNumber: ProjectSchema["number"]): Request<IdMap<ProjectSchema, "project_id">> {
  return traceRequest("projectsRequest", async () => {
    if (isGuid(projectNumber)) {
      const environmentContextClient = environmentContextService();
      return await toMap(
        unpaginate(
          (after) => environmentContextClient.getProjects({ projectId: [projectNumber], after }),
          (res) => res.data.projects,
          (res) => res.data.paging?.cursors?.after
        ),
        (project) => project.project_id,
        (project) => project
      );
    } else {
      if (!/^\d{6}$|^\d{8}$/.test(projectNumber)) return new Map();
      if (projectNumber.length === 6) projectNumber = `${projectNumber}00`;
      const environmentContextClient = environmentContextService();
      return await toMap(
        unpaginate(
          (after) => environmentContextClient.getProjects({ number: [projectNumber], after }),
          (res) => res.data.projects,
          (res) => res.data.paging?.cursors?.after
        ),
        (project) => project.project_id,
        (project) => project
      );
    }
  });
}

export function datasetsRequest(templateId: TemplateSchema["id"]): Request<IdMap<DataSetSchema, "id">> {
  return traceRequest("datasetsRequest", async () => {
    const parameterClient = parameterService();
    return await toMap(
      unpaginate(
        (after) => parameterClient.getDataSets({ templateId: [templateId], after }),
        (res) => res.data.data_sets,
        (res) => res.data.paging?.cursors?.after
      ),
      (dataset) => dataset.id,
      (dataset) => dataset
    );
  });
}

export function templatesRequest(id: ProjectSchema["project_id"]): Request<IdMap<TemplateSchema, "id">> {
  return traceRequest("templatesRequest", async () => {
    const env = templateService();
    return await toMap(
      unpaginate(
        (after) => env.getTemplates({ projectId: [id], after }),
        (res) => res.data.templates,
        (res) => res.data.paging?.cursors?.after
      ),
      (template) => template.id,
      (template) => template
    );
  });
}

export function assetHierarchysRequest(assetId: AssetSchema["id"]): Request<IdMap<Array<AssetHierarchyRef>, "id">> {
  return traceRequest("assetHierarchysRequest", async () => {
    const parameterClient = parameterService();
    const hierarchies = await parameterClient.getAssetHierarchy({ assetIds: [assetId] });
    const hierarchiesForAsset = hierarchies.data.hierarchies[0];
    return new Map([[assetId, hierarchiesForAsset]]);
  });
}
