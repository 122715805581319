import { Store } from "@tanstack/store";
import { DdbEnvironment, QueryFormat, SavedQueries, Query } from "../shared/types";
import { load, save } from "./data-persist/custom-xml";

export interface StoreSchema {
  step: number;
  currentQuery: Pick<Query, "format" | "environment"> & Partial<Query>;
  queries: SavedQueries;
}

export const INITIAL_STATE = {
  step: 0,
  currentQuery: {
    format: QueryFormat.Manual,
    environment: DdbEnvironment.Develop,
  },
  queries: [],
} satisfies StoreSchema;

export const STORE = new Store<StoreSchema>(INITIAL_STATE);

export async function loadQueries() {
  const state = await load();
  const { queries } = state || {};
  if (queries) {
    STORE.setState((state) => ({ ...state, queries }));
  }
}

export async function saveQueries() {
  const { queries } = STORE.state;
  await save({ queries });
}

loadQueries();
