import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { DeleteRegular, OpenRegular } from "@fluentui/react-icons";
import { VirtualizerScrollView } from "@fluentui/react-components/unstable";
import { ProjectSchema } from "@ddb/environment-context-service";
import { environmentContextService } from "../../shared/ddb";
import { Query, DdbEnvironment } from "../../shared/types";
import { STORE, saveQueries } from "../store";

async function getProject(environment: DdbEnvironment, projectId: ProjectSchema["project_id"]): Promise<ProjectSchema> {
  try {
    const env = environmentContextService(environment);
    const project = await env.getProjectById({ projectId: projectId as unknown as object });
    return project.data.project;
  } catch {
    throw new Error(`Failed to get project data for project id ${projectId} in DDB (${environment}).`);
  }
}

const useStyles = makeStyles({
  root: { display: "grid", gap: "1rem" },
  queries: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    maxHeight: "750px",
  },
  query: {
    height: "130px",
    lineHeight: "100px",
    width: "100%",
  },
});

interface SavedQueryProps {
  query: Query;
  index: number;
}

const SavedQuery: React.FC<SavedQueryProps> = ({ query, index }) => {
  const styles = useStyles();
  const projectQuery = useQuery<ProjectSchema>({
    queryKey: ["project-data", query.environment, query.project_id],
    queryFn: () => getProject(query.environment, query.project_id!),
  });

  const handleSelectClick = () => {
    STORE.setState((state) => ({ ...state, step: 4, currentQuery: query }));
  };

  const handleDeleteClick = async (index: number) => {
    STORE.setState((state) => {
      const newQueries = state.queries.filter((_, i) => i !== index);
      return { ...state, queries: newQueries };
    });
    await saveQueries();
  };

  const project = projectQuery.data;
  const bgColor = index % 2 === 0 ? tokens.colorNeutralBackground1 : tokens.colorNeutralBackground2;

  return (
    <div className={styles.query} key={index} style={{ backgroundColor: bgColor }}>
      <div>
        <div style={{ display: "grid", lineHeight: "1rem" }}>
          <span style={{ fontSize: "1.1em" }}>
            <strong>{query.name}</strong>
          </span>
          <span>{project?.short_title}</span>
          <span>{project?.number}</span>
          <span style={{ fontStyle: "italic" }}>Env: {query.environment}</span>
          <span style={{ fontStyle: "italic" }}>Format: {query.format}</span>
        </div>
        <span style={{ display: "flex", gap: "0.2rem", justifyContent: "flex-end" }}>
          <Button size="small" appearance="primary" onClick={() => handleSelectClick()} icon={<OpenRegular />} />
          <Button size="small" onClick={() => handleDeleteClick(index)} icon={<DeleteRegular />} />
        </span>
      </div>
    </div>
  );
};

const SavedQueries: React.FC = () => {
  const styles = useStyles();
  const queries = useStore(STORE, ({ queries }) => queries);

  return (
    <div className={styles.root}>
      <Button appearance="primary" onClick={() => STORE.setState((state) => ({ ...state, step: 1 }))}>
        New Query
      </Button>

      {queries.length === 0 && <div>No queries saved</div>}
      {queries.length > 0 && (
        <VirtualizerScrollView
          numItems={queries.length}
          itemSize={30}
          container={{ role: "list", style: { height: "500px" } }}
        >
          {(index) => <SavedQuery query={queries[index]} index={index} />}
        </VirtualizerScrollView>
      )}
    </div>
  );
};

export default SavedQueries;
