export async function first<T>(iterable: AsyncIterable<T>, predicate?: (value: T) => boolean): Promise<T | undefined> {
  for await (const value of iterable) {
    if (predicate === undefined || predicate(value)) {
      return value;
    }
  }
  return undefined;
}

export async function at<T>(iterable: AsyncIterable<T>, index: number): Promise<T | undefined> {
  if (index < 0) {
    return undefined;
  }
  let i = 0;
  for await (const value of iterable) {
    if (i++ === index) {
      return value;
    }
  }
  return undefined;
}

export async function toArray<T>(iterable: AsyncIterable<T>): Promise<T[]> {
  const result: T[] = [];
  for await (const value of iterable) {
    result.push(value);
  }
  return result;
}

export async function toMap<T, K, V>(
  iterable: AsyncIterable<T>,
  keySelector: (value: T) => K,
  valueSelector?: (value: T) => V
): Promise<Map<K, V>> {
  const result = new Map();
  for await (const value of iterable) {
    result.set(keySelector(value), valueSelector ? valueSelector(value) : value);
  }
  return result;
}

export async function* map<T, U>(
  iterable: AsyncIterable<T>,
  callbackfn: (value: T, index: number) => U | Promise<U>
): AsyncIterable<U> {
  let index = 0;
  for await (const value of iterable) {
    yield await callbackfn(value, index++);
  }
}

export async function* filter<T>(
  iterable: AsyncIterable<T>,
  predicate: (value: T, index: number) => boolean
): AsyncIterable<T> {
  let index = 0;
  for await (const value of iterable) {
    if (predicate(value, index++)) {
      yield value;
    }
  }
}

export async function reduce<T, U>(
  iterable: AsyncIterable<T>,
  callbackfn: (previousValue: U, currentValue: T, currentIndex: number) => U | Promise<U>,
  initialValue: U
): Promise<U> {
  let index = 0;
  let accumulator = initialValue;
  for await (const value of iterable) {
    accumulator = await callbackfn(accumulator, value, index++);
  }
  return accumulator;
}
