/* global Office */

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { initialize as initializeOpenTelemtry } from "../shared/opentelemetry";
import { initialize as initializePosthog } from "../shared/posthog";
import { App } from "./components/App";
import { ENVIRONMENT } from "../shared/config";
import { Environment } from "../shared/types";
import { diag } from "@opentelemetry/api";

const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* initialize opentelemetry and posthog */
initializeOpenTelemtry();
initializePosthog();

Office.onReady(async (info) => {
  // TODO: this has been setup to check that we are loading the right manifest.
  //       should be removed post beta testing phase.
  if (info.host === Office.HostType.Excel) {
    diag.debug("DDB-Excel Add-in is running in Excel");
    let manifestUrl: string;
    switch (ENVIRONMENT) {
      case Environment.Development:
        manifestUrl = "https://excel.dev.ddb.arup.com/manifest.excel.xml";
        break;
      case Environment.UT:
        manifestUrl = "https://excel.sandbox.ddb.arup.com/manifest.excel.xml";
        break;
      case Environment.Production:
        manifestUrl = "https://excel.ddb.arup.com/manifest.excel.xml";
        break;
      default:
        manifestUrl = "https://localhost:3000/manifest.excel.xml";
        break;
    }

    fetch(manifestUrl)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "application/xml");
        const version = xmlDoc.getElementsByTagName("Version")[0].childNodes[0].nodeValue;
        diag.debug(`Manifest Version: ${version}`);
      })
      .catch((error) => diag.error("Error fetching or parsing the manifest file:", error));
  }

  /* render the app */
  root?.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}).catch((error) => diag.error("Error initializing the add-in:", error));

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}
