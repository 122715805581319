import React, { FC } from "react";
import { Link } from "react-router";
import { Skeleton, Stack, Typography, IconButton } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { projectsRequest } from "../../shared/requests";
import { OTEL_RESOURCE } from "../../shared/config";
import { ATTR_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import { DdbEnvironment } from "../../shared/types";
import { STORE } from "../store";

export const CurrentQuery: FC = () => {
  const { currentQuery } = useStore(STORE);
  const projects = useQuery({
    queryKey: ["project-data", currentQuery.environment, currentQuery.project_id],
    queryFn: projectsRequest(currentQuery.project_id || ""),
  });

  function urlToProject(): string {
    switch (currentQuery.environment) {
      case DdbEnvironment.Production:
        return `https://ddb.aurp.com/projects/${currentQuery.project_id}`;
      case DdbEnvironment.Sandbox:
        return `https://sandbox.ddb.arup.com/projects/${currentQuery.project_id}`;
      case DdbEnvironment.Develop:
        return `https://dev.ddb.arup.com/projects/${currentQuery.project_id}`;
      default:
        return `https://ddb.arup.com/projects/${currentQuery.project_id}`;
    }
  }

  const serviceName = String(OTEL_RESOURCE.attributes[ATTR_SERVICE_NAME]);

  return (
    <Stack direction="row" spacing={1} justifyContent={"end"}>
      {projects.isLoading && <Skeleton variant="text" width={100} />}
      {projects.isSuccess && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">
            {projects.data?.get(currentQuery.project_id || "")?.short_title} (
            {projects.data.get(currentQuery.project_id || "")?.project_number})
          </Typography>
          <Link to={urlToProject()} target="_blank" rel={serviceName}>
            <IconButton>
              <LinkIcon />
            </IconButton>
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
