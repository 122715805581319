import React, { FC, ReactElement } from "react";
import { AppBar, Box, Button, Toolbar, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { OTEL_RESOURCE } from "../../shared/config";
import { attrsToHumanString } from "../utils";

let actionButton: ReactElement;

export function setActionButton(element: ReactElement) {
  actionButton = element;
}

function getActionButton(): ReactElement {
  if (actionButton) {
    return actionButton as ReactElement;
  }
  return <></>;
}

export const BottomBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const actionButton = getActionButton();
  const attrsString = attrsToHumanString(OTEL_RESOURCE.attributes, " | ");

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Box sx={{ display: "grid", gap: 4, width: "100%", padding: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Button variant="text" disabled={location.pathname === "/"} onClick={() => navigate(-1)}>
              Back
            </Button>
            {actionButton}
          </Box>
          <Tooltip title={attrsString}>
            <Typography
              variant="caption"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {attrsString}
            </Typography>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
