import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Stack,
  ListItemText,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { ProjectSchema } from "@ddb/environment-context-service";
import { projectsRequest } from "../../shared/requests";
import { QueryFormat, QUERY_FORMAT_DESCRIPTIONS } from "../../shared/types";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { humanizeString } from "../utils";
import { STORE } from "../store";
import { setActionButton } from "./BottomBar";

const NextButton: React.FC = () => {
  const navigate = useNavigate();
  const { project_id, format } = useStore(STORE, ({ currentQuery }) => currentQuery);

  function handleGotoProject(): void {
    if (project_id) {
      STORE.setState((state) => ({
        ...state,
        currentQuery: {
          ...state.currentQuery,
          filters: {},
        },
      }));
      navigate(format === QueryFormat.Manual ? "/edit" : "/create/template");
    }
  }

  return (
    <Button variant="contained" onClick={handleGotoProject}>
      Next
    </Button>
  );
};

const ProjectSelect: React.FC = () => {
  const { format } = useStore(STORE, ({ currentQuery }) => currentQuery);
  const [projectNumber, setProjectNumber] = useState("");
  const projects = useQuery({
    queryKey: ["project-search", projectNumber],
    queryFn: projectsRequest(projectNumber),
  });

  function handleProjectSelect(_: React.ChangeEvent<{}>, project: ProjectSchema | null): void {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, project_id: project?.project_id, id: undefined },
    }));
  }

  function handleProjectNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setProjectNumber(e.target.value);
    if (e.target.value === "") {
      projects.refetch();
    }
  }

  function handleFormatChange(e: SelectChangeEvent<QueryFormat>): void {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, format: e.target.value as QueryFormat },
    }));
  }

  setActionButton(<NextButton />);

  return (
    <Stack spacing={4}>
      <FormControl fullWidth>
        <InputLabel>Format</InputLabel>
        <Select label="Format" value={format} onChange={handleFormatChange}>
          {Object.values(QueryFormat).map((format) => (
            <MenuItem key={format} value={format}>
              <ListItemText>
                <Typography variant="body1" noWrap={true} sx={{ pl: 1 }}>
                  {humanizeString(format)}
                </Typography>
                <Typography variant="body2" color="secondary" noWrap={true} sx={{ pl: 1 }}>
                  {QUERY_FORMAT_DESCRIPTIONS[format]}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        disablePortal
        options={Array.from(projects.data?.values() || [])}
        loading={projects.isLoading}
        getOptionLabel={({ number, short_title }) => `${number}: ${short_title}`}
        onChange={handleProjectSelect}
        renderInput={(params) => <TextField {...params} label="Job Number" onChange={handleProjectNumberChange} />}
        renderTags={(projects) => {
          if (projects.length === 0)
            return (
              <Typography noWrap={true} sx={{ pl: 1 }}>
                Search for a project
              </Typography>
            );
          const project = projects[0];
          const { number, short_title } = project;
          return <Typography noWrap={true} sx={{ pl: 1 }}>{`${number}: ${short_title}`}</Typography>;
        }}
      />
    </Stack>
  );
};

export default ProjectSelect;
