import React, { FC } from "react";
import { Typography } from "@mui/material";

interface EmptyStateProps {
  text: string;
}

export const EmptyState: FC<EmptyStateProps> = ({ text }) => {
  return <Typography color="secondary">{text}</Typography>;
};

export default EmptyState;
