import React, { FC } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { Skeleton, Stack, Chip, Card, CardActions, CardContent, Typography, Button } from "@mui/material";
import { makeStyles } from "@fluentui/react-components";
import { Query, SavedQuery as SQuery } from "../../shared/types";
import { STORE, saveQueries } from "../store";
import { queryRequest } from "../../shared/requests";
import { DDB_ENVIRONMENT } from "../../shared/config";
import { AssetSchema, ParameterSchema } from "@ddb/parameter-service";
import { setActionButton } from "./BottomBar";
import EmptyState from "./EmptyState";

const useStyles = makeStyles({ queries: { display: "grid", gap: "1rem" } });

interface SavedQueryProps {
  query: SQuery & Query;
}

const SavedQuery: FC<SavedQueryProps> = ({ query }) => {
  const navigate = useNavigate();
  const parameterQuery = useQuery({
    refetchInterval: 120000, // 2 minutes
    queryKey: ["parameters", query.id || crypto.randomUUID()],
    retryOnMount: true,
    queryFn: queryRequest(query),
  });
  const project = parameterQuery.data?.project;
  const assets = parameterQuery.data?.assets || new Map<AssetSchema["id"], AssetSchema>();
  const params = parameterQuery.data?.params || new Map<ParameterSchema["id"], ParameterSchema>();

  function handleSelectClick() {
    STORE.setState((state) => ({ ...state, currentQuery: query }));
    navigate("/edit");
  }

  async function handleDeleteClick() {
    STORE.setState((state) => {
      const newQueries = state.queries.filter((q) => q.id !== query.id);
      return { ...state, queries: newQueries };
    });
    await saveQueries();
  }

  return (
    <Card>
      <CardContent>
        {parameterQuery.isLoading && <Skeleton variant="text" width={100} />}
        {parameterQuery.isError && <Typography color="error">Failed to load project data</Typography>}
        {parameterQuery.isSuccess && (
          <>
            <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
              {project?.short_title}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 1.5 }}>{project?.number}</Typography>
          </>
        )}

        <Stack direction="row" spacing={1}>
          {parameterQuery.isLoading && <Skeleton variant="text" width={100} />}
          {parameterQuery.isError && <Typography color="error">Failed to load parameters</Typography>}
          {parameterQuery.isSuccess && <Chip label={`${assets.size} assets`} />}
          {parameterQuery.isSuccess && <Chip label={`${params.size} parameters`} />}
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSelectClick} disabled={parameterQuery.isLoading}>
          Open
        </Button>
        <Button size="small" onClick={handleDeleteClick} disabled={parameterQuery.isLoading}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

const SavedQueries: FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const queries = useStore(STORE, ({ queries }) => queries);
  queries.filter(({ environment }) => environment === DDB_ENVIRONMENT);

  function handleNewQueryClick() {
    navigate("/create");
  }

  setActionButton(<></>);

  return (
    <div className={styles.queries}>
      <Button variant="contained" color="primary" onClick={handleNewQueryClick}>
        New Query
      </Button>
      {queries.length === 0 && <EmptyState text="No saved queries" />}
      {queries.length > 0 && queries.map((query) => <SavedQuery key={query.id} query={query} />)}
    </div>
  );
};

export default SavedQueries;
