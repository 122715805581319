import React, { FC } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { datasetsRequest } from "../../shared/requests";
import {
  CircularProgress,
  Stack,
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  Button,
  Checkbox,
} from "@mui/material";
import { DataSetSchema } from "@ddb/parameter-service";
import { isTemplateQuery } from "../../shared/types";
import { STORE } from "../store";
import { CurrentQuery } from "./CurrentQuery";
import { setActionButton } from "./BottomBar";
import EmptyState from "./EmptyState";

interface DatasetProps {
  dataset: DataSetSchema;
}

const Dataset: FC<DatasetProps> = ({ dataset }) => {
  function handlecheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const checked = e.target.checked;
    STORE.setState((state) => {
      if (!isTemplateQuery(state.currentQuery)) return state;
      const data_sets_ids = state.currentQuery.data_sets_ids || [];
      const index = data_sets_ids.findIndex((id) => id === dataset.id);
      if (checked && index === -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: [...data_sets_ids, dataset.id],
          },
        };
      } else if (!checked && index !== -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: data_sets_ids.filter((id) => id !== dataset.id),
          },
        };
      }
      return state;
    });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <FormControlLabel control={<Checkbox />} label={dataset.name} onChange={handlecheckboxChange} />
      </CardContent>
    </Card>
  );
};

const NextButton: FC = () => {
  const navigate = useNavigate();

  function Next() {
    STORE.setState((state) => ({
      ...state,
      currentQuery: {
        ...state.currentQuery,
        filters: {},
      },
    }));
    navigate("/edit");
  }

  return (
    <Button variant="contained" onClick={Next}>
      Next
    </Button>
  );
};

const DatasetSelect: FC = () => {
  const template_id = useStore(STORE, ({ currentQuery }) =>
    isTemplateQuery(currentQuery) ? currentQuery.template_id : undefined
  );
  if (!template_id) throw new Error("Template is required for DatasetSelect.");
  const datasets = useQuery({
    queryKey: ["datasets", template_id],
    retry: false,
    queryFn: datasetsRequest(template_id),
  });

  setActionButton(<NextButton />);

  return (
    <Stack direction="column" spacing={8}>
      <CurrentQuery />
      <Typography variant="h4">Select Dataset(s)</Typography>
      {datasets.isPending && <CircularProgress sx={{ alignSelf: "center" }} />}
      {datasets.isSuccess && datasets.data?.size === 0 && <EmptyState text="No datasets exist for selected template" />}
      {datasets.isSuccess &&
        Array.from(datasets.data?.values() || []).map((dataset) => <Dataset key={dataset.id} dataset={dataset} />)}
    </Stack>
  );
};

export default DatasetSelect;
