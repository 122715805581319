import * as React from "react";
import { useStore } from "@tanstack/react-store";
import { useQuery } from "@tanstack/react-query";
import {
  makeStyles,
  useToastController,
  tokens,
  Button,
  Toast,
  ToastTitle,
  ToastBody,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Input,
  Label,
} from "@fluentui/react-components";
import { ArrowPreviousRegular, ArrowNextRegular, ArrowSyncCircleRegular } from "@fluentui/react-icons";
import { VirtualizerScrollView } from "@fluentui/react-components/unstable";
import { AssetSchema, ParameterSchema } from "@ddb/parameter-service";
import { SavedQuery, isTemplateQuery, isSavedQuery } from "../../shared/types";
import { parameterService } from "../../shared/ddb";
import { INITIAL_STATE, STORE, saveQueries } from "../store";
import { writeValuesToCells } from "../excel";

const useStyles = makeStyles({
  assetsTable: {
    width: "100%",
    tableLayout: "fixed",
    borderRadius: tokens.borderRadiusLarge,
    border: "1px solid " + tokens.colorNeutralStroke2,
    maxHeight: "500px",
    height: "500px",
  },
  asset: {
    border: "1px solid " + tokens.colorNeutralStroke2,
    height: "30px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
});

type AssetSchemaAndParameterSchema = [Array<AssetSchema>, Array<ParameterSchema>];

const SelectParameters: React.FC = () => {
  const styles = useStyles();
  const { dispatchToast } = useToastController("global-toaster");
  const [currentParam, setCurrentParam] = React.useState<ParameterSchema | undefined>();
  const currentQuery = useStore(STORE, ({ currentQuery }) => currentQuery);
  const { project_id, environment } = currentQuery;
  const data_sets_ids = isTemplateQuery(currentQuery) ? currentQuery.data_sets_ids : undefined;
  const parameterQuery = useQuery<AssetSchemaAndParameterSchema>({
    refetchInterval: 120000, // 2 minutes
    queryKey: ["parameters", environment, project_id, data_sets_ids],
    retryOnMount: true,
    queryFn: async () => {
      try {
        if (!project_id) throw new Error("No project selected");
        const env = parameterService(environment);
        const assetsReq = await env.getAssets({ projectId: [project_id] });
        let { assets } = assetsReq.data;
        if (assets.length === 0) throw new Error("No assets found in the data set(s) selected");
        const paramsReq = await env.getAllParameters({
          assetId: assets.map(({ id }) => id),
          projectId: [project_id],
          dataSetId: data_sets_ids,
        });
        const { parameters } = paramsReq.data;
        assets = assets.filter(({ id }) => parameters.some(({ parents }) => parents.map(({ id }) => id).includes(id)));
        if (assets.length === 0) throw new Error("No parameters found in the data set(s) selected");
        setCurrentParam(parameters[0]);
        return [assets, parameters];
      } catch (error) {
        dispatchToast(
          <Toast>
            <ToastTitle>Error</ToastTitle>
            <ToastBody subtitle="Error">An error occurred while fetching parameters.</ToastBody>
          </Toast>,
          { intent: "error" }
        );
        throw error;
      }
    },
  });

  const [assets, parameters] = parameterQuery.data || [[], []];

  const currentIndex = parameters.findIndex((param) => param.id === currentParam?.id);
  const handleNextParamClick = () => {
    const nextParam = parameters[currentIndex + 1];
    if (nextParam) setCurrentParam(nextParam);
  };
  const handlePrevParamClick = () => {
    const prevParam = parameters[currentIndex - 1];
    if (prevParam) setCurrentParam(prevParam);
  };
  const handleInsertClick = () => {
    writeValuesToCells(environment, assets, parameters);
  };
  const handleSaveQueryClick = async (ev: React.FormEvent) => {
    ev.preventDefault();
    const formData = new FormData(ev.target as HTMLFormElement);
    const name = formData.get("name") as string;
    if (isSavedQuery(currentQuery)) {
      const query: SavedQuery = { ...currentQuery, name, project_id: currentQuery.project_id! };
      STORE.setState((state) => {
        const queries = state.queries.map((q) => (q.id === query.id ? query : q));
        return { ...state, queries, currentQuery: INITIAL_STATE.currentQuery, step: 0 };
      });

      await saveQueries();
    } else {
      const query: SavedQuery = {
        ...currentQuery,
        name,
        project_id: currentQuery.project_id!,
        id: crypto.randomUUID(),
      };
      STORE.setState((state) => ({
        ...state,
        queries: [...state.queries, query],
        step: 0,
        currentQuery: INITIAL_STATE.currentQuery,
      }));
      await saveQueries();
    }
  };

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <span>
        <strong>Displaying:</strong> ({assets.length}) Assets, ({parameters.length}) Parameters
      </span>

      <table className={styles.assetsTable}>
        <th style={{ display: "table-header-group" }}>
          <td>
            <Button
              size="small"
              appearance="transparent"
              icon={<ArrowSyncCircleRegular />}
              onClick={() => parameterQuery.refetch()}
            />
            <span>Asset Type</span>
          </td>
          <td>Asset Name</td>
          <td>Path</td>
          <td>Parameter Type</td>
          <td>Parameter Value</td>
        </th>

        <VirtualizerScrollView
          numItems={assets.length}
          itemSize={30}
          container={{ style: { maxHeight: "500px", overflowY: "scroll", display: "table-row-group" } }}
        >
          {(index) => {
            const asset = assets[index];
            const paramBelongsToAsset = (param: ParameterSchema | undefined) =>
              param?.parents.map(({ id }) => id).includes(asset.id) || false;
            const paramName = (param: ParameterSchema) => (paramBelongsToAsset(param) ? param.parameter_type.name : "");
            const paramValue = (param: ParameterSchema) => {
              if (!paramBelongsToAsset(param)) return "";
              const value = param.selected_entry?.values.at(0)?.value;
              const unit = param.selected_entry?.values.at(0)?.unit?.symbol;
              return unit === undefined ? value : `${value} (${unit})`;
            };
            const rowColor = index % 2 === 0 ? tokens.colorNeutralBackground1 : tokens.colorNeutralBackground2;
            const renderCell = (value: string) => (
              <td style={{ textAlign: "center" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={value}>
                  {value}
                </div>
              </td>
            );
            return (
              <tr
                className={styles.asset}
                style={{ backgroundColor: rowColor }}
                aria-posinset={index}
                aria-setsize={assets.length}
                key={index}
              >
                {renderCell(asset.asset_type.name)}
                {renderCell(asset.name)}
                {renderCell("xxxx")}
                {renderCell(paramName(currentParam!))}
                {renderCell(paramValue(currentParam!))}
              </tr>
            );
          }}
        </VirtualizerScrollView>

        <span style={{ display: "table-footer-group", height: "30px", position: "relative" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              position: "absolute",
              right: "0",
            }}
          >
            <Button
              size="small"
              appearance="transparent"
              icon={<ArrowPreviousRegular />}
              onClick={handlePrevParamClick}
            />
            <span> {`${currentIndex + 1} / ${parameters.length}`} </span>
            <Button size="small" appearance="transparent" icon={<ArrowNextRegular />} onClick={handleNextParamClick} />
          </span>
        </span>
      </table>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={handleInsertClick}>Insert Table Into Excel</Button>
        <Dialog modalType="non-modal">
          <DialogTrigger disableButtonEnhancement>
            <Button>{isSavedQuery(currentQuery) ? "Update Query" : "Save New Query"}</Button>
          </DialogTrigger>
          <DialogSurface aria-describedby={undefined}>
            <form onSubmit={handleSaveQueryClick}>
              <DialogBody>
                <DialogTitle>Dialog title</DialogTitle>
                <DialogContent className={styles.content}>
                  <Label required htmlFor={"name-input"}>
                    Query Name
                  </Label>
                  <Input required id={"name-input"} name={"name"} value={currentQuery.name} />
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Close</Button>
                  </DialogTrigger>
                  <Button type="submit" appearance="primary">
                    {isSavedQuery(currentQuery) ? "Update Query" : "Save New Query"}
                  </Button>
                </DialogActions>
              </DialogBody>
            </form>
          </DialogSurface>
        </Dialog>
      </div>
    </div>
  );
};

export default SelectParameters;
