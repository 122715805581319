import * as React from "react";
import { useIsFetching } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { makeStyles, tokens, ProgressBar, Toaster, Button } from "@fluentui/react-components";
import { STORE } from "../store";
import SavedQueries from "./SavedQueries";
import ProjectSelect from "./ProjectSelect";
import SelectParameters from "./SelectParameters";
import TemplateSelect from "./TemplateSelect";
import DatasetSelect from "./DatasetSelect";
import Refresh from "./Refresh";

const useStyles = makeStyles({
  layout: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  progressBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  content: {
    padding: "1rem",
    position: "fixed",
    top: 0,
    bottom: "2rem",
    left: 0,
    right: 0,
    overflowY: "auto",
  },
  footer: {
    backgroundColor: tokens.colorBackgroundOverlay,
    color: tokens.colorNeutralForeground1,
    position: "fixed",
    height: "2rem",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
  },
});

function renderStep(step: number) {
  switch (step) {
    case 0:
      return <SavedQueries />;
    case 1:
      return <ProjectSelect />;
    case 2:
      return <TemplateSelect />;
    case 3:
      return <DatasetSelect />;
    case 4:
      return <SelectParameters />;
    default:
      return null;
  }
}

const Layout: React.FC = () => {
  const styles = useStyles();
  const loading = useIsFetching({ predicate: (query) => query.queryKey[0] !== "lastRefreshed" });
  const currentStep = useStore(STORE, ({ step }) => step);

  return (
    <div className={styles.layout}>
      <Toaster toasterId="global-toaster" />
      {loading > 0 && <ProgressBar className={styles.progressBar} thickness="large" />}

      <div className={styles.content}>{renderStep(currentStep)}</div>

      <div className={styles.footer}>
        <Button
          color="primary"
          onClick={() =>
            STORE.setState((state) => ({
              ...state,
              currentQuery: { ...state.currentQuery, data_sets: undefined, template: undefined, project: undefined },
              step: 0,
            }))
          }
          disabled={currentStep === 0}
        >
          Back
        </Button>
        <Refresh />
      </div>
    </div>
  );
};

export default Layout;
